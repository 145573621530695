import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../components/layout'
// import Lightbox from 'react-images'
import Gallery from '../components/Gallery'

import thumb01 from '../assets/images/thumbs/01.jpg'
import thumb02 from '../assets/images/thumbs/02.jpg'
import thumb03 from '../assets/images/thumbs/03.jpg'

import full01 from '../assets/images/fulls/01.jpg'
import full02 from '../assets/images/fulls/02.jpg'
import full03 from '../assets/images/fulls/03.jpg'

// import avatar from '../assets/images/avatar.png'

const DEFAULT_IMAGES = [
    { id: '1', src: full01, thumbnail: thumb01, caption: 'Elzas'},
    { id: '2', src: full02, thumbnail: thumb02, caption: 'Faugère'},
    { id: '3', src: full03, thumbnail: thumb03, caption: 'Je boirai du lait'},
];

class HomeIndex extends React.Component {

    constructor() {
        super();

        this.state = {
            lightboxIsOpen: false,
            currentImage: 0,
        };

        this.closeLightbox = this.closeLightbox.bind(this);
        this.gotoNext = this.gotoNext.bind(this);
        this.gotoPrevious = this.gotoPrevious.bind(this);
        this.openLightbox = this.openLightbox.bind(this);
        this.handleClickImage = this.handleClickImage.bind(this);
    }

    openLightbox (index, event) {
        event.preventDefault();
        this.setState({
            currentImage: index,
            lightboxIsOpen: true,
        });
    }
    closeLightbox () {
        this.setState({
            currentImage: 0,
            lightboxIsOpen: false,
        });
    }
    gotoPrevious () {
        this.setState({
            currentImage: this.state.currentImage - 1,
        });
    }
    gotoNext () {
        this.setState({
            currentImage: this.state.currentImage + 1,
        });
    }
    handleClickImage () {
        if (this.state.currentImage === this.props.images.length - 1) return;

        this.gotoNext();
    }

    render() {
        const siteTitle = "Vina Sana"
        const siteDescription = "Invoer en groothandel van wijnen"

        return (
            <Layout>
                <Helmet>
                        <title>{siteTitle}</title>
                        <meta name="description" content={siteDescription} />
                </Helmet>

                <div id="main">
                    {/* <img src={avatar} alt="" /> */}
                    <section id="one">
                        <header className="major">
                            <h2>Wat we doen</h2>
                        </header>                        
                        <ul>
                            <li>Invoer en Groothandel.</li>
                            <li>Toelevering uitsluitend aan HoReCa.</li>
                            <li>Franse wijnen, wereldwijnen, bio-wijnen.</li>
                            <li>Champagne, Cava, Schuimwijnen.</li>
                            <li>Advies wijn en gerecht.</li>                    
                            <li>Suggestie- en maandwijnen.</li>
                            <li>Leveringen per karton of per pallet.</li>
                            <li>Degustaties op maat.</li>                    
                        </ul>
                    </section>

                    <section id="two">
                        <h2>Sfeerbeelden</h2>

                        <Gallery images={DEFAULT_IMAGES.map(({ id, src, thumbnail, caption, description }) => ({
                            src,
                            thumbnail,
                            caption,
                            description
                        }))} />
                    </section>

                    <section id="three">
                        <h2>Contact</h2>
                        <ul className="labeled-icons">
                            <li>
                                <h3 className="icon fa-mobile"><span className="label">Telefoon</span></h3>
                                <a href="tel:+32476200968">0476 200 968</a>
                            </li>
                            <li>
                                <h3 className="icon fa-envelope-o"><span className="label">Email</span></h3>
                                <a href="mailto:vina.sana@telenet.be">vina.sana@telenet.be</a>
                            </li>
                        </ul>
                    </section>

                </div>

            </Layout>
        )
    }
}

export default HomeIndex